* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* color: #333; */
  line-height: 1.5;
}

html,
body,
#root {
  height: 100%;
  scroll-padding-top: 5rem;
}

button,
input,
textarea {
  font-family: "Comfortaa", cursive;
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

/* Remove margin top from main content if snap scrolling is enabled */
main:not(.pg-container) {
  padding-top: 135px;
}

@media screen and (min-width: 1024px) {
  main:not(.pg-container) {
    padding-top: 142px;
  }
  main.home {
    margin-top: 0rem;
  }
}
footer {
  flex-shrink: 0;
}

/* Hide footer if snap scrolling is enabled in page */
.pg-wrapper + footer {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  5%,
  10%,
  16%,
  20%,
  100% {
    transform: translateY(0);
  }
  7% {
    transform: translateY(-30px);
  }
  13% {
    transform: translateY(-15px);
  }
}

li {
  margin: 1rem auto;
}

/* Allow images to be aligned with text based on last character in the "alt" description */
img[alt$=">"] {
  float: right;
  padding: 1rem;
}

img[alt$="<"] {
  float: left;
  padding: 1rem;
}
