@font-face {
    font-family: 'Manrope';
    src: url('../../public/fonts/Manrope-Regular.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Manrope-Medium';
    src: url('../../public/fonts/Manrope-Medium.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Manrope-Bold';
    src: url('../../public/fonts/Manrope-Bold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('../../public/fonts/Manrope-ExtraBold.ttf') format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Antarctica Mono';
    src: url('../../public/fonts/AntarcticanMono-Book.ttf');
}
