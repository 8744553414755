@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./typography";

#root {
  height: 100%;
}
html {
  scroll-behavior: smooth;
  font-family: "Manrope", sans-serif;
}

input, textarea {
  @apply focus:ring-0 #{!important};
}
html,
body {
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: -40px;
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}
